import * as Actions from '../actions';

const initialState = {
    role: []
};

const user = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_USER_DATA: {
            console.log(action.payload);
            return {
                role:action.payload[0].rol,
                ...action.payload
            };
        }
        case Actions.REMOVE_USER_DATA: {
            return {
                ...initialState
            };
        }
        case Actions.USER_LOGGED_OUT: {
            return initialState;
        }
        default: {
            return state
        }
    }
};

export default user;
