import React from 'react';
import { AuthRoles } from "components/auth";

export const ManageCashConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/manage_cash`,
            component: React.lazy(() => import('./ManageCash'))
        }
    ]
};