import React from 'react';
import { AuthRoles } from "components/auth";

export const RoomConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/room`,
            component: React.lazy(() => import('./Room'))
        }
    ]
};