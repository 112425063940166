import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as userActions from 'components/auth/store/actions';
import { bindActionCreators } from 'redux';
import authService from 'services/authService';
class Auth extends Component {
    state = {
        waitAuthCheck: true
    }
    
    componentDidMount() {
        
        return Promise.all([
            this.tokenCheck()
        ]).then(() => {
            this.setState({ waitAuthCheck: false })
        })
    }

    tokenCheck = () => new Promise(resolve => {
        console.log("path",this.props.location.pathname.substring(1).length);
        authService.on('onAutoLogin', () => {
            authService.signInWithToken((this.props.location.pathname.substring(1).length==0)?undefined:this.props.location.pathname.substring(1))
                .then(user => {
                    this.props.setUserData(user);
                    resolve();
                })
                .catch(error => {
                    resolve();
                })
        });

        authService.on('onAutoLogout', (message) => {
            /* if (message) {
                console.log(message);
            } */

            this.props.logout();

            resolve();
        });

        authService.on('onNoAccessToken', () => {

            resolve();
        });

        authService.init();

        return Promise.resolve();
    })

    render() {
        return this.state.waitAuthCheck ? <h1>Iniciando sesión</h1> : <React.Fragment children={this.props.children} />;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout: userActions.logoutUser,
        setUserData: userActions.setUserData,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);
