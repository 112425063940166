import mock from "./../mock";
import _ from "@lodash";

const config = {
    access_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjQ5NjIzMTkzMDY2NzhhNGRkYjVlZjk2MjZiMzRhYzJiNGY5NWEwZTE2NTdkOTQwMDgzYmJlMGJkMmVjYmMxNTRkMjIyYTczM2QxMWYzYjkiLCJpYXQiOjE1NzgxMDkxNjksIm5iZiI6MTU3ODEwOTE2OSwiZXhwIjoxNjA5NzMxNTY5LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.kux6p9tuuJLm2OvdpPQwu7tYwqHyzSHvXELOhvfrTYnO6MqyDwEj0Z9XetoZOCE-IExleYk9l3ImAPea5rl0ng6NMmLO76dBBxL1Dxie5K8vg1C43xlR2WtTY0RNCTo5VQwozrLdrCwz872Atr6lMV11yV4_WVoRWEGSNPMTma6t79Ub7iou3Kvxu52ouVvZ0CooY1rAfnsBKa0B--pqZaVXuWzTH4XImDZO43aAhjhon4o-SJ96v2hrf6Kcmb9UjLKcluRIAVVMpRHJ-_USELKaqhVzLbzbMkADaZEMkVSm1fziXbtcCSN8ig-aegI1dlcErMMwcJ4vArvHcoYVvOpcy5ejXzWWhmRfpsTgDH8Nz1I5EAh9PCJ7il2O6hNlnT9mBdJDIYKjtIsWakbAGKGUHh4JQEnvDrB8Xn6eS4E51cDgbDxogYm9ukg85AH_h8LpmN3faTB6TJ0vVGXsgmrkojz_VstbRltB4lunWgwfqvLljykrbGdxUQgQY1-dkDr3aafUQ3XK7kSNTKNtKteHEkxg9d69FvRHHtOgz7NPT8jWGKKb9RYeLKfs6dVL8bRlgqoEACqlRXR1dAvh_Px2ofok583JOlp4iEeGtvOKJWmtbik4PeD0WuZlYr4XdLWkJYQpXJq0zOWWWYLp4ofIqutzSLVlGhT3np2E7H4",
    token_type: "Bearer",
    expires_at: "2020-01-11 03-39-29"
}

let authDB = {
    users: [
        {
            id: 1,
            name: "Bretts Acuña",
            username: "brettsacuna",
            password: "brettsacuna$",
            role: "admin",
            email: "brettsacuna@gmail.com",
            created_at: "2019-12-31 01:14:05",
            updated_at: "2019-12-31 01:14:05"
        }
    ]
}

mock.onPost('/api/auth/login').reply((request) => {
    const data = JSON.parse(request.data);
    const { username, password } = data;

    const user = _.cloneDeep(authDB.users.find(_user => _user.username === username));

    const error = {
        username: user ? null : "Revise su nombre de usuario",
        password: user && user.password === password ? null : "Revise su contraseña"
    }

    if (!error.username && !error.password) {
        delete user['password'];

        return [200, {
            ...config,
            user: user
        }];
    } else {
        return [200, { error }];
    }
});

mock.onGet('/api/auth/validate-token').reply((request) => {
    console.log("TEstt");
    if (localStorage.getItem("access_token")) {
        
        return [200, {
            valid: true,
            user: authDB.users[0],
        }];
    } else {
        return [200, {
            valid: false,
        }];
    }
});