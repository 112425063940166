/*
    Configuración: routesConfig
    Descripción: Archivo de configuración de las rutas de la aplicación
*/

import React from "react";
import { Redirect } from "react-router-dom";
import AppUtils from "@app/AppUtils";

import { LoginConfig } from "components/pages/login/LoginConfig";
import { GridConfig } from "components/pages/grid/GridConfig";
import { ProductsConfig } from "components/pages/products/ProductsConfig";
import { ReportByMonthConfig } from "components/pages/report_by_month/ReportByMonthConfig";
import { SalesSummaryConfig } from "components/pages/sales_summary/SalesSummaryConfig";
import { CategoriesBrandConfig } from "components/pages/category_brand/CategoriesBrandConfig";
import { ProductsListConfig } from "components/pages/products_list/ProductsListConfig";
import { DashboardConfig } from "components/pages/dashboard/DashboardConfig";
import { RoomConfig } from "components/pages/room/RoomConfig";
import { TouristPackagesConfig } from "components/pages/tourist_packages/TouristPackagesConfig";
import { ClientsProvidersConfig } from "components/pages/clients_providers/ClientsProvidersConfig";
import { VoucherConfig } from "components/pages/voucher/VoucherConfig";
import { EstablishmentsConfig } from "components/pages/establishments/EstablishmentsConfig";
import { SeriesConfig } from "components/pages/series/SeriesConfig";
import { UsersConfig } from "components/pages/users/UsersConfig";
import { SalesConfig } from "components/pages/sales/SalesConfig";
import { OrdersConfig } from "components/pages/orders/OrdersConfig";
import { ProformasConfig } from "components/pages/proformas/ProformasConfig";
import { ManageCashConfig } from "components/pages/manage_cash/ManageCashConfig";
import { AccessControlConfig } from "components/pages/access_control/AccessControlConfig";
import { ExpensesReceiptConfig } from "components/pages/expenses_receipt/ExpensesReceiptConfig";
import { IncomeReceiptConfig } from "components/pages/income_receipt/IncomeReceiptConfig";
import { ManageCpeConfig } from "components/pages/manage_cpe/ManageCpeConfig";
import { CreditNoteConfig } from "components/pages/credit_note/CreditNoteConfig";
import { ManagementPurchasesMerchandiseConfig } from "components/pages/management_purchases_merchandise/ManagementPurchasesMerchandiseConfig";
import { ElectronicGuideConfig } from "components/pages/electronic_guide/ElectronicGuideConfig";
import { MenuControlConfig } from "components/pages/menu_control/MenuControlConfig";
import { ClassRoomConfig } from "components/pages/class_room/ClassRoomConfig";
import { BookingConfig } from "components/pages/booking/BookingConfig";
import { ReportSalesConfig } from "components/pages/report_sales/ReportSalesConfig";

const routesConfig = [
  LoginConfig,
  GridConfig,
  ProductsConfig,
  ReportByMonthConfig,
  SalesSummaryConfig,
  CategoriesBrandConfig,
  ProductsListConfig,
  DashboardConfig,
  RoomConfig,
  TouristPackagesConfig,
  ClientsProvidersConfig,
  VoucherConfig,
  EstablishmentsConfig,
  SeriesConfig,
  UsersConfig,
  SalesConfig,
  OrdersConfig,
  ProformasConfig,
  ManageCashConfig,
  AccessControlConfig,
  ExpensesReceiptConfig,
  IncomeReceiptConfig,
  ManageCpeConfig,
  CreditNoteConfig,
  ManagementPurchasesMerchandiseConfig,
  ElectronicGuideConfig,
  MenuControlConfig,
  ClassRoomConfig,
  BookingConfig,
  ReportSalesConfig,
];

const routes = [
  ...AppUtils.generateRoutesFromConfigs(routesConfig, null),
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />,
  },
  {
    component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />,
  },
];

export default routes;
