import React from 'react';
import { AuthRoles } from "components/auth";

export const ClientsProvidersConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/clients_providers`,
            component: React.lazy(() => import('./ClientsProviders'))
        }
    ]
};