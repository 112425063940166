import React from 'react';
import { AuthRoles } from "components/auth";

export const DashboardConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/dashboard`,
            component: React.lazy(() => import('./Dashboard'))
        }
    ]
};