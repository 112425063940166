import React from 'react';
import { AuthRoles } from "components/auth";

export const TouristPackagesConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/tourist-packages`,
            component: React.lazy(() => import('./TouristPackages'))
        }
    ]
};