import React, { useEffect, useState } from "react";
import Header from "../header";
import Aside from "../aside";
import Footer from "../footer";
import { toast } from "react-toastify";
import * as PropTypes from "prop-types";

import { useSelector } from "react-redux";
const Content = (props) => {
  const pathValidate = useSelector((gridApp) =>
    gridApp.auth.user ? gridApp.auth.user[0].pathValidate : false
  );
  const [sidebarCollapse, setSidebarCollapse] = useState(
    localStorage.getItem("sidebar_collapse") || true
  );
  useEffect(() => {
    if (pathValidate === false) {
      toast.error("No tiene acceso al recurso");
    }
  }, [pathValidate]);
  useEffect(() => {
    console.log(pathValidate);
    document.body.classList.add("hold-transition");
    document.body.classList.add("sidebar-mini");
    document.body.classList.add("layout-fixed");

    if (sidebarCollapse === true || sidebarCollapse === "true") {
      document.body.classList.add("sidebar-collapse");
      if (document.querySelector(".scheduler")) {
        document
          .querySelector(".scheduler-view")
          .classList.add("max-scheduler");
      }
    } else {
      if (document.querySelector(".scheduler")) {
        document
          .querySelector(".scheduler-view")
          .classList.add("res-scheduler");
        document.querySelector(".scheduler").classList.add("scheduler-res");
      }
    }
    return () => {
      document.body.className = "";
    };
  }, []);
  function updateCollapseSidebar() {
    setSidebarCollapse(!sidebarCollapse);
    localStorage.setItem(
      "sidebar_collapse",
      document.body.classList.contains("sidebar-collapse")
    );
    if (!document.querySelector(".scheduler")) return;
    if (!sidebarCollapse) {
      document
        .querySelector(".scheduler-view")
        .classList.remove("res-scheduler");
      document.querySelector(".scheduler").classList.remove("scheduler-res");
      document.querySelector(".scheduler-view").classList.add("max-scheduler");
    } else {
      document.querySelector(".scheduler").classList.add("scheduler-res");
      document.querySelector(".scheduler-view").classList.add("res-scheduler");
      document
        .querySelector(".scheduler-view")
        .classList.remove("max-scheduler");
    }
  }

  return (
    <div className="wrapper">
      <Header updateCollapseSidebar={updateCollapseSidebar} />
      <Aside />

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">{props.section}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/#">Inicio</a>
                  </li>
                  <li className="breadcrumb-item active">{props.title}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            {pathValidate === false ? (
              <h1>No tiene acceso al recurso</h1>
            ) : (
              props.content
            )}
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

Content.prototypes = {
  title: PropTypes.node,
  section: PropTypes.node,
  content: PropTypes.node,
};

Content.defaultProps = {};

export default Content;
