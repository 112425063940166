import React from 'react';
import { AuthRoles } from "components/auth";

export const ManageCpeConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/manage_cpe`,
            component: React.lazy(() => import('./ManageCpe'))
        }
    ]
};