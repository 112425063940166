import React from 'react';
import { AuthRoles } from "components/auth";

export const GridConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/grid`,
            component: React.lazy(() => import('./Grid'))
        }
    ]
};