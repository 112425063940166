import axios from "axios";
import * as Actions from "components/auth/store/actions";
import { toast } from "react-toastify";

import { getAllSales } from "./sales.actions";
import { listCreditNote } from "./credit_note.actions";

export const LOADING_CPE_ACTION = "[MANAGE CPE] LOADING_CPE_ACTION";
export const LOADER_SEND_MAIL = "[MANAGE CPE] LOADER SEND MAIL";
export const CONSUlTA_COMPROBANTE_SUNAT =
  "[MANAGE CPE] CONSULTA COMPROBANTE SUNAT";

export function generateVoucher(form, iduser, pagePagination = 1) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/generacomprobante`,
    form
  );
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true });
    toast.info("Generando...", {
      toastId: "generateVoucher",
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem("access_token")) {
            localStorage.removeItem("access_token");
            delete axios.defaults.headers.common["Authorization"];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        if (response.data.status == 200) {
          toast.update("generateVoucher", {
            render: response.data.mensaje,
            type: "success",
            autoClose: 1500,
          });
        } else {
          toast.update("generateVoucher", {
            render: response.data.mensaje,
            type: "error",
            autoClose: 5000,
          });
        }

        if (form.tipo_documento == 1) {
          dispatch(getAllSales(iduser, pagePagination));
        } else {
          dispatch(listCreditNote());
        }

        return dispatch({ type: LOADING_CPE_ACTION, payload: false });
      })
      .catch((error) => {
        toast.update("generateVoucher", {
          render: error.message,
          type: "error",
          autoClose: 5000,
        });
        return dispatch({ type: LOADING_CPE_ACTION, payload: false });
      });
  };
}

export function firmarVoucher(form, iduser, pagePagination = 1) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/firmacomprobante`,
    form
  );
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true });
    toast.info("Firmando...", { toastId: "signVoucher", autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem("access_token")) {
            localStorage.removeItem("access_token");
            delete axios.defaults.headers.common["Authorization"];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        if (response.data.status == 200) {
          toast.update("signVoucher", {
            render: response.data.mensaje,
            type: "success",
            autoClose: 1500,
          });
        } else {
          toast.update("signVoucher", {
            render: response.data.mensaje,
            type: "error",
            autoClose: 5000,
          });
        }

        if (form.tipo_documento == 1) {
          dispatch(getAllSales(iduser, pagePagination));
        } else {
          dispatch(listCreditNote());
        }

        return dispatch({ type: LOADING_CPE_ACTION, payload: false });
      })
      .catch((error) => {
        toast.update("signVoucher", {
          render: error.message,
          type: "error",
          autoClose: 5000,
        });
        return dispatch({ type: LOADING_CPE_ACTION, payload: false });
      });
  };
}

export function enviarVoucher(form, iduser, pagePagination = 1) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/enviarcomprobante`,
    form
  );
  return (dispatch) => {
    dispatch({ type: LOADING_CPE_ACTION, payload: true });
    toast.info("Enviando a SUNAT...", {
      toastId: "sendVoucher",
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem("access_token")) {
            localStorage.removeItem("access_token");
            delete axios.defaults.headers.common["Authorization"];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        if (response.data.status == 200) {
          toast.update("sendVoucher", {
            render: response.data.mensaje,
            type: "success",
            autoClose: 1500,
          });
        } else {
          toast.update("sendVoucher", {
            render: response.data.mensaje,
            type: "error",
            autoClose: 5000,
          });
        }

        if (form.tipo_documento == 1) {
          dispatch(getAllSales(iduser, pagePagination));
        } else {
          dispatch(listCreditNote());
        }

        return dispatch({ type: LOADING_CPE_ACTION, payload: false });
      })
      .catch((error) => {
        toast.update("sendVoucher", {
          render: error.message,
          type: "error",
          autoClose: 5000,
        });
        return dispatch({ type: LOADING_CPE_ACTION, payload: false });
      });
  };
}

export function consultaSunat(form, iduser, pagePagination = 1) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/consultasunat`,
    form
  );
  return (dispatch) => {
    toast.info("Consultando en SUNAT...", {
      toastId: "consultVoucher",
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem("access_token")) {
            localStorage.removeItem("access_token");
            delete axios.defaults.headers.common["Authorization"];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        if (response.data.status == 200) {
          toast.update("consultVoucher", {
            render: response.data.mensaje,
            type: "success",
            autoClose: 1500,
          });
        } else {
          toast.update("consultVoucher", {
            render: response.data.mensaje,
            type: "error",
            autoClose: 5000,
          });
        }

        if (form.tipo_documento == 1) {
          return dispatch(getAllSales(iduser, pagePagination));
        } else {
          return dispatch(listCreditNote());
        }
      })
      .catch((error) => {
        return toast.update("consultVoucher", {
          render: error.message,
          type: "error",
          autoClose: 5000,
        });
      });
  };
}

export function sendMailCPE(form) {
  toast.info("Enviando mail...", {
    toastId: "sendMailRegisterEstancia",
    autoClose: false,
  });
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/sendcpe`,
    form
  );

  return (dispatch) =>
    request
      .then((response) => {
        console.log(response.data);
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem("access_token")) {
            console.log(response.data.detalle);
            localStorage.removeItem("access_token");
            delete axios.defaults.headers.common["Authorization"];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        toast.update("sendMailRegisterEstancia", {
          render: response.data.mensaje,
          type: parseInt(response.data.status) == 200 ? "success" : "error",
          isLoading: false,
          autoClose: 2000,
        });
        return dispatch(loaderSendMailCPE(false));
      })
      .catch((error) => {
        dispatch(loaderSendMailCPE(false));
        toast.update("sendMailRegisterEstancia", {
          render: error.message,
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      });
}

export function reverceCPE(form, iduser, pagePagination = 1) {
  toast.info("Revirtiendo CPE...", {
    toastId: "reverceEstancia",
    autoClose: false,
  });
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/revercecpe`,
    form
  );

  return (dispatch) =>
    request
      .then((response) => {
        console.log(response.data);
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem("access_token")) {
            console.log(response.data.detalle);
            localStorage.removeItem("access_token");
            delete axios.defaults.headers.common["Authorization"];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        toast.update("reverceEstancia", {
          render: response.data.mensaje,
          type: parseInt(response.data.status) == 200 ? "success" : "error",
          isLoading: false,
          autoClose: 2000,
        });
        if (form.tipo_documento == 1) {
          return dispatch(getAllSales(iduser, pagePagination));
        }
      })
      .catch((error) => {
        dispatch(loaderSendMailCPE(false));
        toast.update("reverceEstancia", {
          render: error.message,
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      });
}

export function loaderSendMailCPE(status) {
  return (dispatch) =>
    dispatch({
      type: LOADER_SEND_MAIL,
      payload: status,
    });
}
