import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { Link } from "react-router-dom";
import * as authActions from "components/auth/store/actions";
import { useDispatch, useSelector } from "react-redux";
import reducer from "components/auth/store/reducers";
import withReducer from "store/withReducer";

const Login = () => {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);
  const loader = useSelector(({ auth }) => auth.login.loader);
  const { handleSubmit, register, errors } = useForm();
  const formRef = useRef(null);
  const onSubmit = (values) => {
    dispatch(authActions.submitLogin(values.username, values.password));
  };

  useEffect(() => {
    document.body.classList.add("hold-transition");
    document.body.classList.add("login-page");

    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <div className="login-box">
      <div className="login-logo">
        <a href="/">
          <b>Tampu</b>
        </a>
      </div>
      <div className="card">
        <div className="card-body login-card-body">
          <p className="login-box-msg">
            Ingrese sus credenciales de usuario para acceder a nuestro sistema
          </p>
          {login.error !== null && (
            <div className="alert alert-danger">
              <h5>
                <i className="icon fas fa-ban"></i> Mensaje del sistema
              </h5>
              {login.error}
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <div className={clsx("input-group", errors.username ? "" : "mb-3")}>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  errors.username ? "is-invalid" : ""
                )}
                name="username"
                placeholder="Ingrese usuario"
                autoFocus={true}
                ref={register({
                  required: "* Campo obligatorio",
                })}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-user"></span>
                </div>
              </div>
            </div>
            {errors.username && (
              <p className="text-danger mb-2 small">
                {errors.username.message}
              </p>
            )}
            <div className={clsx("input-group", errors.password ? "" : "mb-3")}>
              <input
                type="password"
                className={clsx(
                  "form-control",
                  errors.password ? "is-invalid" : ""
                )}
                name="password"
                placeholder="Ingrese contraseña"
                ref={register({
                  required: "* Campo obligatorio",
                })}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>
            {errors.password && (
              <p className="text-danger mb-2 small">
                {errors.password.message}
              </p>
            )}
            <div className="row">
              <div className="col-6 offset-6">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={loader}
                >
                  {loader ? ". . ." : "Iniciar sesión"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p className="text-center">
        <small>
          <strong>
            Copyright &copy; 2020{" "}
            <Link to={process.env.PUBLIC_URL}>ACCORD Software</Link>
          </strong>
        </small>
      </p>
    </div>
  );
};

export default withReducer("auth", reducer)(Login);
