import * as Actions from "../../actions/app";
const initialState = {
  class_rooms: null,
  type_rooms: null,
  set_crud_class_room: null,
};
const classRoomReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_CLASS_ROOMS: {
      return {
        ...state,
        class_rooms: [...action.payload],
      };
    }
    case Actions.CRUD_CLASS_ROOM: {
      if (state.set_crud_class_room) {
        return {
          ...state,
          set_crud_class_room: null,
        };
      }
      return {
        ...state,
        set_crud_class_room: action.payload,
      };
    }
    case Actions.GET_ALL_TYPE_ROOMS: {
      return {
        ...state,
        type_rooms: [...action.payload],
      };
    }
    default: {
      return state;
    }
  }
};
export default classRoomReducer;
