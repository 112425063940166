import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Actions from "components/auth/store/actions";
import withDragDropContext from "./helper/withDnDContext";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import "./index.css";
const Sidebar = (props) => {
  const dispatch = useDispatch();
  //Inicializo los estados de las secciones del menu
  const [menuSelect, setMenuSelect] = useState(null);
  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    let tuser = props.userRole;
    if (tuser.rol === "admin") tuser.rol = "Administrador";
    if (tuser.rol === "atm") tuser.rol = "Cajero";
    if (tuser.rol === "seller") tuser.rol = "Vendedor";
    setUserRole(tuser);
    //console.log(props.userRole);
    setMenuSelect(
      localStorage.getItem("menu") ? localStorage.getItem("menu") : null
    );
  }, []);
  useEffect(() => {
    console.log("menu", menuSelect);
    localStorage.setItem("menu", menuSelect);
  }, [menuSelect]);
  //cambio de estado al momento de ejecutar funcion onClick

  return (
    <div
      className="sidebar" /* style={{ overflowY:'hidden', overflowX:'hidden'}} */
    >
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          <img
            src={
              userRole ? userRole.imagen_perfil : "assets/dist/img/avatar5.png"
            }
            className="img-circle elevation-2"
            alt="User"
          />
        </div>
        <div className="info">
          <Link to="/dashboard" className="d-block">
            {userRole ? userRole.username : ""}
          </Link>
        </div>
      </div>

      <nav className="mt-2 mb-4">
        <ul
          className="nav nav-pills nav-sidebar flex-column nav-compact nav-flat"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          {props.userRole.menus.map((menu) => (
            <li className="nav-item has-treeview menu-open" key={menu.menu}>
              <a
                href={menu.url}
                className="nav-link active"
                onClick={(e) => {
                  e.preventDefault();
                  menuSelect == menu.menu
                    ? setMenuSelect(null)
                    : setMenuSelect(menu.menu);
                }}
              >
                <i className={menu.icono}></i>
                <p>
                  {ReactHtmlParser(menu.etiqueta)}
                  {menuSelect == menu.menu ? (
                    <i className="right fas fa-angle-left"></i>
                  ) : (
                    <i className=" right fas fa-angle-up"></i>
                  )}
                </p>
              </a>
              {menuSelect == menu.menu ? (
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    {menu.subMenus.map((submenu) => (
                      <Link
                        to={submenu.url}
                        className="nav-link"
                        key={submenu.menu}
                      >
                        <i className={submenu.icono}></i>
                        <p>{ReactHtmlParser(submenu.etiqueta)}</p>
                      </Link>
                    ))}
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </li>
          ))}

          <li className="nav-header">ACCIONES</li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="/"
              onClick={() => {
                dispatch(Actions.logoutUser());
              }}
            >
              <i className="nav-icon fas fa-power-off text-danger"></i>
              <p className="text">Cerrar sesión</p>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user[0],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDragDropContext(Sidebar));
