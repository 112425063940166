/*
    Componente: AppLoading
    Descripción: Loader cuando la página se redirige hacia otra, es usado cuando el inicio de sesión fue satisfactorio
*/

import React, { useState } from "react";
import { useTimeout } from "@app/hooks/index";
import PropTypes from "prop-types";

const AppLoading = (props) => {
    const [showLoading, setShowLoading] = useState(!props.delay);

    useTimeout(() => {
        setShowLoading(true);
    }, props.delay);

    if (!showLoading) {
        return null;
    }

    return (<h1>Cargando....</h1>);
};

AppLoading.propTypes = {
    delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

AppLoading.defaultProps = {
    delay: false
}

export default AppLoading;