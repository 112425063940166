import React from "react";
import Sidebar from "../sidebar";
import { Link } from "react-router-dom";

const Aside = () => {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link className="brand-link" to="/">
        <img
          src="assets/dist/img/logo-app.jpg"
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{
            opacity: ".8",
            objectFit: "fill",
            minHeight: "33.9px",
            minWidth: "33.9px",
          }}
        />
        <span className="brand-text font-weight-light">Tampu</span>
      </Link>

      <Sidebar />
    </aside>
  );
};

export default Aside;
