import * as Actions from "../../actions/app";
const initialState = {
  loading_cpe_action: false,
  consulta_comprobante_sunat: null,
};
const manageCpeReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.LOADING_CPE_ACTION: {
      return {
        ...state,
        loading_cpe_action: action.payload,
      };
    }
    case Actions.CONSUlTA_COMPROBANTE_SUNAT: {
      return {
        ...state,
        consulta_comprobante_sunat: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default manageCpeReducer;
