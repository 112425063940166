import React from 'react';
import { AuthRoles } from "components/auth";

export const ProformasConfig = {
    auth: AuthRoles.admin,
    routes: [
        {
            path: `${process.env.PUBLIC_URL}/proformas`,
            component: React.lazy(() => import('./Proformas'))
        }
    ]
};