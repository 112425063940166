/*
    Aplicación: App
    Descripción: Inicialización de la aplicación con los distintos componentes
*/

import "@fake-db";
import React from "react";
import store from "./store";
import history from "@history/index";
import routes from "configs/routesConfig";
import AppContext from "./AppContext";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import AppLayout from "@app/AppLayout";
import { Auth } from "components/auth";
import AppAuthorization from "@app/components/AppAuthorization";
import axios from "axios";
import thunk from "redux-thunk";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { withRouter } from "react-router";

/* Establecer la cabecera de petición al API, siempre y cuando exista un token válido de lo contrario te devolverá al login */
if (localStorage.getItem("access_token")) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("access_token");
}

const App = (props) => {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={store}>
        <Auth location={props.location}>
          <Router basename={"/booker"} history={history}>
            <AppAuthorization>
              <AppLayout />
              <ToastContainer position="top-center" autoClose={3000} />
            </AppAuthorization>
          </Router>
        </Auth>
      </Provider>
    </AppContext.Provider>
  );
};

export default withRouter(App);
