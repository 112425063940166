/*
    Context: AppContext
    Descripción: Creación de un contexto de aplicación para compartir datos entre distintos componentes, utilizado por Redux para compartir estados entre los distintos
                 componentes de la aplicación
*/

import React from 'react';

const AppContext = React.createContext({});

export default AppContext;