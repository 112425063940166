import mock from "./../mock";

let roomsDb = {
    rooms: [
        {
            room_id: 1,
            location_id: 1,
            room_number: "200",
            status: 3,
            quantity_person: 3,
            quantity_children: 1,
            area: 22.00
        },
        {
            room_id: 2,
            location_id: 1,
            room_number: "201",
            status: 3,
            quantity_person: 3,
            quantity_children: 1,
            area: 22.00
        },
        {
            room_id: 3,
            location_id: 1,
            room_number: "202",
            status: 3,
            quantity_person: 3,
            quantity_children: 1,
            area: 22.00
        },
        {
            room_id: 4,
            location_id: 1,
            room_number: "203",
            status: 3,
            quantity_person: 3,
            quantity_children: 1,
            area: 22.00
        },
        {
            room_id: 5,
            location_id: 1,
            room_number: "204",
            status: 3,
            quantity_person: 3,
            quantity_children: 1,
            area: 22.00
        },
        {
            room_id: 6,
            location_id: 1,
            room_number: "205",
            status: 3,
            quantity_person: 3,
            quantity_children: 1,
            area: 22.00
        },
        {
            room_id: 7,
            location_id: 1,
            room_number: "206",
            status: 3,
            quantity_person: 3,
            quantity_children: 1,
            area: 22.00
        },
        {
            room_id: 8,
            location_id: 1,
            room_number: "207",
            status: 3,
            quantity_person: 3,
            quantity_children: 1,
            area: 22.00
        },
        {
            room_id: 9,
            location_id: 1,
            room_number: "208",
            status: 3,
            quantity_person: 3,
            quantity_children: 1,
            area: 22.00
        },
        {
            room_id: 10,
            location_id: 1,
            room_number: "209",
            status: 3,
            quantity_person: 3,
            quantity_children: 1,
            area: 22.00
        }
    ]
};

mock.onGet('/api/rooms/all').reply((request) => {
    return [200, {
        rooms: roomsDb,
    }];
});