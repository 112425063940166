import axios from "axios";
import * as Actions from "components/auth/store/actions";

import { toast } from "react-toastify";
export const GET_ALL_CLASS_ROOMS = "[CLASS ROOM] GET ALL CLASS ROOMS";
export const GET_ALL_TYPE_ROOMS = "[CLASS ROOM] GET ALL TYPE ROOMS";
export const CRUD_CLASS_ROOM = "[CLASS ROOM] CRUD CLASS ROOM";

export function getAllClassRooms() {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/clasehabitacion`
  );

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalle);
          localStorage.removeItem("access_token");
          delete axios.defaults.headers.common["Authorization"];
          return dispatch(Actions.logoutUser());
        }
        return;
      }

      return dispatch({
        type: GET_ALL_CLASS_ROOMS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      });
    });
}
export function getAllTypeRooms() {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/tipohabitacion`
  );

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalle);
          localStorage.removeItem("access_token");
          delete axios.defaults.headers.common["Authorization"];
          return dispatch(Actions.logoutUser());
        }
        return;
      }

      return dispatch({
        type: GET_ALL_TYPE_ROOMS,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      });
    });
}
export function saveClassRoom(from) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/rclasehabitacion`,
    from
  );

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalle);
          localStorage.removeItem("access_token");
          delete axios.defaults.headers.common["Authorization"];
          return dispatch(Actions.logoutUser());
        }
        return;
      }
      if (response.data.status === 200) {
        toast.success(response.data.mensaje);
      } else {
        toast.error(response.data.mensaje);
      }
      return dispatch({
        type: CRUD_CLASS_ROOM,
        payload: response.data.mensaje ? response.data.mensaje : null,
      });
    });
}
export function updateClassRoom(from) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/aclasehabitacion`,
    from
  );

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalle);
          localStorage.removeItem("access_token");
          delete axios.defaults.headers.common["Authorization"];
          return dispatch(Actions.logoutUser());
        }
        return;
      }
      if (response.data.status === 200) {
        toast.success(response.data.mensaje);
      } else {
        toast.error(response.data.mensaje);
      }
      return dispatch({
        type: CRUD_CLASS_ROOM,
        payload: response.data.mensaje ? response.data.mensaje : null,
      });
    });
}
export function deleteClassRoom(from) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/eclasehabitacion`,
    from
  );

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalle);
          localStorage.removeItem("access_token");
          delete axios.defaults.headers.common["Authorization"];
          return dispatch(Actions.logoutUser());
        }
        return;
      }
      if (response.data.status === 200) {
        toast.success(response.data.mensaje);
      } else {
        toast.error(response.data.mensaje);
      }
      return dispatch({
        type: CRUD_CLASS_ROOM,
        payload: response.data.mensaje ? response.data.mensaje : null,
      });
    });
}
export function getUpdateStatusClassRoom(form) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/gclasehabitacion/${form}`
  );

  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          console.log(response.data.detalle);
          localStorage.removeItem("access_token");
          delete axios.defaults.headers.common["Authorization"];
          return dispatch(Actions.logoutUser());
        }
        return;
      }
      return dispatch({
        type: CRUD_CLASS_ROOM,
        payload: response.data.detalles === null ? [] : response.data.detalles,
      });
    });
}
