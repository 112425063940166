import React, { useEffect, useState } from 'react';
import './index.css';
import AccordLoader from "./AccordLoader.gif"
const Loader =({show, center})=>{
    const [zindex, setZindex] = useState('10');
    useEffect(() => {
        if(!show){
            setTimeout(()=>{
                setZindex('-1');
            },1000)
        }
    }, [show])
    return(
        <div className={`d-flex ${(show)? '':'hid-loader fadeOut'} ${(center)? 'align-items-center':''} justify-content-center loader position-absolute bg-white`} style={{width:'100%', height:'100%', zIndex:zindex}}>
            {/* <div className={` `}>
                <i className="fas fa-circle-notch fa-3x text-primary mt-5 fa-spin" style={{zIndex:zindex}}></i>
            </div> */}
            <img src={AccordLoader} alt="img-loader" style={{zIndex:zindex, height:"150px", width:"100px",objectFit: "fill"}}></img>
        </div>
    );
}
export default Loader;